<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">contact_form</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2  gap-x-2">
        <div class="box  bg-white h-full">
          <div>
            <div class="p-2 pb-2 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">form_title</p>
                <textarea v-model="fieldJsonData.form_title"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="form_title"
                ></textarea>
              </div>
            </div>
        </div>
      </div>
      <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  components: {
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   heading: "",
      //   description: "",
      // }
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
    console.log("@@@@@@@@@@@@@@@@", this.componentId);
  },
  watch: {
  },
  methods: {
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
        this.$emit('objData', this.fieldJsonData)
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.componentDetailsForEdit = null
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>