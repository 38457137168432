<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">career</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">top_section</p>
           <div class="pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">heading</p>
                    <textarea v-model="fieldJsonData.heading"
                    :class="heading_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="heading"
                    ></textarea>
                    <p v-if="heading_err" class="text-error heading-6">heading is required</p>
                </div>
                <!-- <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">description</p>
                    <textarea v-model="fieldJsonData.description"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="description"
                    ></textarea>
                </div> -->
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">img_url</p>
                    <input v-model="fieldJsonData.img_url"
                    :class="fieldJsonData.img_url_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="img_url"
                    >
                    <p v-if="fieldJsonData.img_url_err" class="text-error heading-6">img_url is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">img_alt</p>
                    <input v-model="fieldJsonData.img_alt"
                    :class="fieldJsonData.img_alt_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="img_alt"
                    >
                    <p v-if="fieldJsonData.img_alt_err" class="text-error heading-6">img_alt is required</p>
                </div>
                <!-- <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">card_inner_img_background_color</p>
                    <input v-model="fieldJsonData.card_inner_img_background_color"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="card_inner_img_background_color"
                    >
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">redirect_to</p>
                    <input v-model="fieldJsonData.redirect_to"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="redirect_to"
                    >
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">cta_in_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-3`" v-model="fieldJsonData.cta_in_new_tab" type="radio" :value="false" :name="`default-radio-3`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-3`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-4`" v-model="fieldJsonData.cta_in_new_tab" type="radio" :value="true" :name="`default-radio-4`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-4`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div> -->
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">action_button</p>
           <div class="pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">heading</p>
                    <textarea v-model="fieldJsonData.action_button.heading"
                    :class="fieldJsonData.action_button.heading_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="heading"
                    ></textarea>
                    <p v-if="fieldJsonData.action_button.heading_err" class="text-error heading-6">heading is required</p>
                </div>
                <!-- <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">description</p>
                    <textarea v-model="fieldJsonData.action_button.description"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="description"
                    ></textarea>
                </div> -->
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">cta_text</p>
                    <input v-model="fieldJsonData.action_button.cta_text"
                    :class="fieldJsonData.action_button.cta_text_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="cta_text"
                    >
                    <p v-if="fieldJsonData.action_button.cta_text_err" class="text-error heading-6">cta_text is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">cta_url</p>
                    <input v-model="fieldJsonData.action_button.cta_url"
                    :class="fieldJsonData.action_button.cta_url_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="cta_url"
                    >
                    <p v-if="fieldJsonData.action_button.cta_url_err" class="text-error heading-6">cta_url is required</p>
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">cta_in_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-3-1`" v-model="fieldJsonData.action_button.cta_in_new_tab" type="radio" :value="false" :name="`default-radio-3-1`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-3-1`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-4-1`" v-model="fieldJsonData.action_button.cta_in_new_tab" type="radio" :value="true" :name="`default-radio-4-1`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-4-1`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
             </div>
          </div>
          <!-- <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveData()">Save</button>
            </div>  
          </div> -->
        </div>
        <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      desc_err: false,
      heading_err: false,
      isError: true,
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   top_section: {
      //       card_inner_img_background_color: "",
      //       description: "",
      //       heading: "",
      //       heading_err: false,
      //       img_url: "",
      //       img_url_err: false,
      //       img_alt: "",
      //       img_alt_err: false,
      //       cta_in_new_tab: false,
      //       redirect_to: null
      //   },
      //   action_button: {
      //       cta_in_new_tab: false,
      //       cta_text: "",
      //       cta_text_err: false,
      //       cta_url: "",
      //       cta_url_err: false,
      //       description: null,
      //       heading: "",
      //       heading_err: false
      //   }
      // }
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
  },
  watch: {
    fieldJsonData: {
      handler () {
        console.log('fieldJsonData 123', this.fieldJsonData)
        if (this.fieldJsonData.heading !== '') {
            this.heading_err = false
            this.isError = false
        }
        if (this.fieldJsonData.img_url !== '') {
          this.fieldJsonData.img_url_err = false
          this.isError = false
        }
        if (this.fieldJsonData.img_alt !== '') {
          this.fieldJsonData.img_alt_err = false
          this.isError = false
        }
        if (this.fieldJsonData.action_button.heading !== '') {
            this.fieldJsonData.action_button.heading_err = false
            this.isError = false
        }
        if (this.fieldJsonData.action_button.cta_text !== '') {
          this.fieldJsonData.action_button.cta_text_err = false
          this.isError = false
        }
        if (this.fieldJsonData.action_button.cta_url !== '') {
          this.fieldJsonData.action_button.cta_url_err = false
          this.isError = false
        }
      },
      deep: true
    }
  },
  methods: {
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
      if (this.fieldJsonData.heading === '') {
        this.heading_err = true
        this.isError = true
      }
      if (this.fieldJsonData.img_url === '') {
        this.fieldJsonData.img_url_err = true
        this.isError = true
      }
      if (this.fieldJsonData.img_alt === '') {
        this.fieldJsonData.img_alt_err = true
        this.isError = true
      }
      if (this.fieldJsonData.action_button.heading === '') {
        this.fieldJsonData.action_button.heading_err = true
        this.isError = true
      }
      if (this.fieldJsonData.action_button.cta_text === '') {
        this.fieldJsonData.action_button.cta_text_err = true
        this.isError = true
      }
      if (this.fieldJsonData.action_button.cta_url === '') {
        this.fieldJsonData.action_button.cta_url_err = true
        this.isError = true
      }
      if (this.isError === false) {
        delete this.fieldJsonData.img_url_err
        delete this.fieldJsonData.img_alt_err
        delete this.fieldJsonData.action_button.heading_err
        delete this.fieldJsonData.action_button.cta_text_err
        delete this.fieldJsonData.action_button.cta_url_err
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
      }
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>