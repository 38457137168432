<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">methodologies</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div>
            <div class="p-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.heading"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
              </div>
              <div v-if="descriptionEditor === false" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openDescription()">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="fieldJsonData.description"></p>
              </div>
              <div v-if="descriptionEditor" class="box col-span-12 p-2 pb-0" v-click-outside="closeDescription">
                <p class="label_css">description</p>
                <editor v-model="fieldJsonData.description"
                placeholder="description"
                ></editor>
              </div>
            </div>
            <div  class="mt-4" style="border: 1px solid gray"></div>
            <p class="label_css pt-2 pl-4 font-bold">methodologies_detail</p>
            <div class="px-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.methodologies_detail.heading"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
              </div>
              <div v-if="methodologiesDescriptionEditor === false" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openMethodologiesDescription()">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="fieldJsonData.methodologies_detail.description"></p>
              </div>
              <div v-if="methodologiesDescriptionEditor" class="box col-span-12 p-2 pb-0" v-click-outside="closeMethodologiesDescription">
                <p class="label_css">description</p>
                <editor v-model="fieldJsonData.methodologies_detail.description"
                placeholder="description"
                ></editor>
              </div>
            </div>
           <div class="px-2 pb-0 grid grid-cols-12">
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">image_url</p>
                <input v-model="fieldJsonData.methodologies_detail.image_url"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="image_url"
                >
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">image_alt</p>
                <input v-model="fieldJsonData.methodologies_detail.image_alt"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="image_alt"
                >
              </div>
            </div>
            <div class="col-span-12 mt-2 p-2 pb-0">
                <p class="label_css">is_left_img</p>
                <div class="flex items-center pb-1 ">
                  <input id="default-radio-3" v-model="fieldJsonData.methodologies_detail.is_left_img" type="radio" :value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300">No</label>
                </div>
                <div class="flex items-center pb-1 col-span-6">
                  <input id="default-radio-4" v-model="fieldJsonData.methodologies_detail.is_left_img" type="radio" :value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300">Yes</label>
                </div>
              </div>
              <div  class="mt-4" style="border: 1px solid gray"></div> 
          </div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-4 font-bold">card_list</p>
           <div v-for="(data, index) in fieldJsonData.methodologies_detail.card_list" :key="index" class="px-2 pb-0 grid grid-cols-12">
                <!-- <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">heading</p>
                    <textarea v-model="data.heading"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="heading"
                    ></textarea>
                </div> -->
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">description</p>
                    <textarea v-model="data.description"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="description"
                    ></textarea>
                </div>
                <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeCardDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.methodologies_detail.card_list.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addCardDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.methodologies_detail.card_list.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
        </div>
        <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
import Editor from '../../../../../../components/editor.vue'
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    Editor
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      desc_err: false,
      heading_err: false,
      button_text_err: false,
      url_to_open_err: false,
      isError: true,
      descriptionEditor: false,
      methodologiesDescriptionEditor: false
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    openDescription () {
      this.methodologiesDescriptionEditor = false
      this.descriptionEditor = true
    },
    closeDescription () {
      this.descriptionEditor = false
    },
    openMethodologiesDescription () {
      this.descriptionEditor = false
      this.methodologiesDescriptionEditor = true
    },
    closeMethodologiesDescription () {
      this.methodologiesDescriptionEditor = false
    },
    addCardDetails () {
      this.fieldJsonData.methodologies_detail.card_list.push({
        heading: null,
        description: "",
      })
      console.log("addddddd", this.fieldJsonData.methodologies_detail.card_list);
    },
    removeCardDetails (index) {
      if (this.fieldJsonData.methodologies_detail.card_list.length !== 1) {
        this.fieldJsonData.methodologies_detail.card_list.splice(index, 1)
      }
      console.log("removeeeeeee", this.fieldJsonData.methodologies_detail.card_list);
    },
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>