<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">transport_dev_solu</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div>
            <div class="p-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.heading"
                :class="heading_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
                <p v-if="heading_err" class="text-error heading-6">heading is required</p>
              </div>
              <div v-if="descriptionEditor === false" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openDescription()">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="fieldJsonData.description"></p>
              </div>
              <div v-if="descriptionEditor" class="box col-span-12 p-2 pb-0" v-click-outside="closeDescription">
                <p class="label_css">description</p>
                <editor v-model="fieldJsonData.description"
                :class="desc_err ? 'border border-error' : ''" 
                placeholder="description"
                ></editor>
              </div>
            </div>
              <div  class="mt-4" style="border: 1px solid gray"></div> 
          </div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">card_list</p>
           <div v-for="(data, index) in fieldJsonData.card_list" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">heading</p>
                    <textarea v-model="data.heading"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="heading"
                    ></textarea>
                </div>
                 <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">back_color</p>
                    <input v-model="data.back_color"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="back_color"
                    >
                </div>
                <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeCardDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.card_list.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addCardDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.card_list.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <p class="label_css pt-2 pl-4 font-bold">right_heading_description</p>
          <div class="px-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.right_heading_description.heading"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
              </div>
              <div v-if="rightDescriptionEditor === false" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openRightDescription()">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="fieldJsonData.right_heading_description.description"></p>
              </div>
              <div v-if="rightDescriptionEditor" class="box col-span-12 p-2 pb-0" v-click-outside="closeRightDescription">
                <p class="label_css">description</p>
                <editor v-model="fieldJsonData.right_heading_description.description"
                placeholder="description"
                ></editor>
              </div>
            </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">right_content_list</p>
           <div v-for="(data, index) in fieldJsonData.right_content_list" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-12 p-2 pb-0">
                    <p class="label_css">heading</p>
                    <textarea v-model="data.heading"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="heading"
                    ></textarea>
                </div>
                <div v-if="tempCardDescriptionIndex !== index" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openCardListDescription(index)">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="data.description"></p>
                </div>
                <div v-if="tempCardDescriptionIndex === index && cardDescriptionEditor === false" class="box col-span-12 p-2 pb-0">
                    <p class="label_css">description</p>
                    <editor v-model="data.description"
                    placeholder="description"
                    ></editor>
                </div>
                <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeRightContentDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.right_content_list.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addRightContentDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.right_content_list.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
             <div class="p-2 pb-0 grid grid-cols-12">
              <p class="label_css pl-2 font-bold col-span-12">action_button</p>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">button_text</p>
                <input v-model="fieldJsonData.action_button.button_text"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="button_text"
                >
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">url_to_open</p>
                <input v-model="fieldJsonData.action_button.url_to_open"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="url_to_open"
                >
              </div>
              <div class="col-span-12 mt-2 p-2 pb-0">
                <p class="label_css">cta_in_new_tab</p>
                <div class="flex items-center pb-1 ">
                  <input id="default-radio-3" v-model="fieldJsonData.action_button.open_in_new_tab" type="radio" :value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300">No</label>
                </div>
                <div class="flex items-center pb-1 col-span-6">
                  <input id="default-radio-4" v-model="fieldJsonData.action_button.open_in_new_tab" type="radio" :value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300">Yes</label>
                </div>
              </div>
             </div>
        </div>
        <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
import Editor from '../../../../../../components/editor.vue'
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    Editor
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      desc_err: false,
      heading_err: false,
      button_text_err: false,
      url_to_open_err: false,
      isError: true,
      descriptionEditor: false,
      rightDescriptionEditor: false,
      cardDescriptionEditor: false,
      tempCardDescriptionIndex: null,
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    addCardDetails () {
      this.fieldJsonData.card_list.push({
        back_color: "",
        description: null,
        heading: "",
        sub_heading: null
      })
      console.log("addddddd", this.fieldJsonData.card_list);
    },
    removeCardDetails (index) {
      if (this.fieldJsonData.card_list.length !== 1) {
        this.fieldJsonData.card_list.splice(index, 1)
      }
      console.log("removeeeeeee", this.fieldJsonData.card_list);
    },
    addRightContentDetails () {
      this.fieldJsonData.right_content_list.push({
        description: "",
        heading: ""
      })
      console.log("addddddd", this.fieldJsonData.right_content_list);
    },
    removeRightContentDetails (index) {
      if (this.fieldJsonData.right_content_list.length !== 1) {
        this.fieldJsonData.right_content_list.splice(index, 1)
      }
      console.log("removeeeeeee", this.fieldJsonData.right_content_list);
    },
    openDescription () {
      this.cardDescriptionEditor = false
      this.descriptionEditor = true
    },
    closeDescription () {
      this.descriptionEditor = false
    },
    openRightDescription () {
      this.descriptionEditor = false
      this.rightDescriptionEditor = true
    },
    closeRightDescription () {
      this.rightDescriptionEditor = false
    },
    openCardListDescription (index) {
      this.descriptionEditor = false
      this.rightDescriptionEditor = false
      this.tempCardDescriptionIndex = index
    },
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>