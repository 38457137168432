import { render, staticRenderFns } from "./our_core_values.vue?vue&type=template&id=6d9520ae&scoped=true&"
import script from "./our_core_values.vue?vue&type=script&lang=js&"
export * from "./our_core_values.vue?vue&type=script&lang=js&"
import style0 from "./our_core_values.vue?vue&type=style&index=0&id=6d9520ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d9520ae",
  null
  
)

export default component.exports