<template>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-12 gap-x-2">
        <div class="box col-span-7 bg-white h-full cust_card_comp">
          <div>
            <div class="p-2 pb-0 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Name</p>
                <input v-model="pageObj.blogPageName"
                :class="pagnameErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Blog Page Name"
                >
                <p v-if="pagnameErr" class="text-error heading-6">Blog Name is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Display Title</p>
                <input v-model="pageObj.blogPageDisplayTitle"
                :class="dtitlexErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Blog Page Display Title"
                >
                <p v-if="dtitlexErr" class="text-error heading-6">Blog Display Title is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Page URL</p>
                <input v-model="pageObj.blogPageUrlSlug"
                :class="pageUrlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Page URL"
                >
                <p v-if="pageUrlErr" class="text-error heading-6">Page URL is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Blog Page Description</p>
                <textarea class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
                  placeholder="Blog Page Short Description"
                  :class="descErr ? 'border border-error' : ''"
                  outlined=""
                  auto-grow=""
                  v-model="pageObj.blogPageShortDescription"
                ></textarea>
                <p v-if="descErr" class="text-error heading-6">Blog Page Description is Required</p>
              </div>
              <div class="box col-span-7 p-2 pb-0">
                <p class="label_css">Button URL</p>
                <input v-model="pageObj.blogPageDetailBtnUrl"
                :class="btnurlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Button URL"
                >
                <p v-if="btnurlErr" class="text-error heading-6">Button URL is Required</p>
              </div>
              <div class="box col-span-5 p-2 pb-0">
                <p class="label_css">Button Text</p>
                <input v-model="pageObj.blogPageDetailBtnText"
                :class="btntextErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Button Text"
                >
                <p v-if="btntextErr" class="text-error heading-6">Button Text is Required</p>
              </div>
              <div class="box col-span-7 p-2 pb-0">
                <p class="label_css">Image/Video URL</p>
                <input v-model="pageObj.blogPageImageOrVideo"
                :class="imgurlErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Image/Video URL"
                >
                <p v-if="imgurlErr" class="text-error heading-6">Image/Video URL is Required</p>
              </div>
              <div class="box col-span-5 p-2 pb-0">
                <p class="label_css">Image/Video Alt</p>
                <input v-model="pageObj.blogPageImageOrVideoAlt"
                :class="imgaltErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Image/Video Alt"
                >
                <p v-if="imgaltErr" class="text-error heading-6">Image/Video Alt is Required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0 flex items-center">
                <label class="cu_switch cursor-pointer" :class="!pageObj.blogPageOpenInNewTab ? 'ml-2' : ''">
                  <input type="checkbox"  v-model="pageObj.blogPageOpenInNewTab">
                  <span class="cu_slider cu_round"></span>
                </label>
                <p class="heading-5 text-text2 pl-3">{{pageObj.blogPageOpenInNewTab ? 'Blog page button redirect new page.' : 'Blog page button redirect same page.'}}</p>
              </div>
            </div>
            <!-- <div class=" pt-0 grid  border border-gray-400 m-4 rounded" v-for="(data, index) in pageObj.pageSections" :key="index"> -->
              <!-- <div class="box " :class="pageObj.pageSections.length > 1 ? 'col-span-11 p-4 pr-0' : 'col-span-12 p-4'">
                <p class="label_css">Section Name</p>
                <input v-model="data.sectionName"
                :class="data.sectionErr ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Section Name"
                @keyup="changeUnderScore(data.sectionName, 'sName', index)"
                >
                <p v-if="data.sectionErr" class="text-error heading-6">Section Name is Required</p>
              </div> -->
              <!-- <div class="p-4 pt-0 box col-span-12" v-if="data.isShow && data.pageSectionFields !== null"> -->
                <!-- <draggable :list="data.pageSectionFields" group="people" @start="drag = true" @end="dragArray" id='list'> -->
                  <!-- <div class="grid grid-cols-12 gap-x-3" v-for="(childData, childIndex) in data.pageSectionFields" :key="childIndex">
                    <div class=" pt-2 box col-span-6">
                      <p class="label_css">Component{{childData.isRepeated === true ? ' (Is Repeatable)' : ''}}</p>
                      <input v-model="childData.selComponentName"
                      disabled
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                      placeholder="Section Name"
                      >
                    </div>
                    <div class=" pt-2" :class="data.pageSectionFields.length > 1 ? 'box col-span-5' : 'box col-span-6'">
                      <p class="label_css">Display Name</p>
                      <input v-model="childData.fieldDisplayName"
                      disabled
                      class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                      placeholder="Section Name"
                      >
                    </div>
                  <div style="position:absolute;justify-self:right;margin-top: 35px;" class="">
                    <button v-if="data.pageSectionFields.length > 1" class=" rounded-full h-7 w-7 bg-red-500 mr-1 cursor-pointer"
                      @click="removeField(childIndex, index)"
                      >
                      <i class="fas fa-trash text-white heading-4 pt-1"></i>
                    </button>
                  </div>
                </div>
              </div> -->
              <!-- <div class="p-2 pt-0 flex box col-span-12">
                <p class=" text-primary pl-2 heading-5 cursor-pointer" @click="addField(index)">+ Add Field</p>
              </div> -->
              <draggable :list="allComponentsListArr" group="people" @start="drag = true" @end="dragArray" id='list'>
                <div v-for="(data, index) in allComponentsListArr" :key="index" class="min-w-min cursor-pointer border-r border-l border-b border-gray1 shadow-md bg-white hover:bg-gray-100 text-sm px-3 p-2 m-2 items-center">
                  <div class="flex justify-between">
                    <div class="flex items-center">
                      <div class="right-16 cursor-pointer mr-1">
                          <i class="fa-solid fa-grip-vertical text-gray3 h-4 w-4"></i>
                      </div>
                      <div class="max-w-max mx-2 border border-gray2 overflow-hidden">
                        <span v-if="data.blogComponentImageUrl !== undefined">
                        <img  class="" style="height: 50px; width:50px" :src="data.blogComponentImageUrl" alt="component_image">
                        </span>
                        <span v-else>
                        <img  class="" style="height: 50px; width:50px" :src="whenAddCompImgUrl" alt="component_image">
                        </span>
                      </div>
                      <p class="text-lg ml-2">{{data.contentDetail.component_name}}</p>
                    </div>
                    <div style="" class="flex items-center">
                      <p class="flex items-center">
                        <span class="px-1 pr-3 cursor-pointer" @click="editComponentDetails(data, index, data.blogComponentImageUrl)">
                          <i class="fas fa-edit text-primary h-5 w-5" ></i>
                        </span>
                      </p>
                    <button  class=" rounded-full h-7 w-7 bg-red-500 mr-1 cursor-pointer" @click="removeEditComponentDetails(index)">
                      <i class="fas fa-trash-alt text-white heading-4 pt-1"></i>
                    </button>
                  </div>
                  </div>
                </div>
              </draggable>
              <div v-for="(data, index) in allComponentsListArr" :key="'C' + index">
                <div v-if="data.contentDetail.component_name === 'contact_form'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Contact_form @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'content_with_images'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Content_with_images @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'top_banner'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Top_banner @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'left_or_right_image'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Left_or_right_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'heading_with_columns'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Heading_with_columns @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'front_content_background_image'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Front_content_background_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'single_image'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Single_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'heading_with_content'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Heading_with_content @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'heading_with_button'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Heading_with_button @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'banner'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Banner @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'left_content_right_cards'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Left_content_with_four_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'our_services'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Our_services @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'technology_stack'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Technology_stack @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'our_work'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Our_work @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'industries'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Industries @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'brandicons'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Brand_icons @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'hover_cards'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Hover_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'developer_services'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Developer_services @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'border_cards'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Border_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'flexible_hire_model'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Flexible_hire_model @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'dedicated_wp_dev'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Dedicated_wp_dev @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'engagement_models'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Engagement_models @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'hire_table'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Hire_table @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'faqs'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Faqs @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'table_content_section'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Table_content_section @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'transport_dev_solu'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Transport_dev_solu @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'our_core_values'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Our_core_values @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'faqs_group'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Faqs_group @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'bg_button_section'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Bg_button_section @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'client_testimonials'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Client_testimonials @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'dev_methodologies'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Dev_methodologies @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'methodologies'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Methodologies @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'chairman_message'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Chaireman_message @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'overview'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Overview @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'multiple_heading_with_content'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Multiple_heading_with_content @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'hover_shadow_cards'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Hover_shadow_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'career'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <career @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'offices'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Offices @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'add_value'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Add_value @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'roundedimage_on_cards'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Rounded_image_on_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
                <div v-if="data.contentDetail.component_name === 'opportunity_with_us'">
                  <div v-if="data.contentDetail.isCompVisible"  class="popup_overlay">
                    <div class="custom_dialog" style="width:550px">
                      <Opportunity_with_us @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :componentImageUrl="comp_img_url" />
                    </div>
                  </div>
                </div>
              </div>
               <div v-if="deleteSpecificComponent" class="popup_overlay">
                <div style="height: 120px; width: 500px;" class="custom_dialog">
                  <div class="text-text1 px-4 py-3 flex justify-between items-center">
                    <span class="heading-3">Are You Sure ?</span>
                    <div class="flex justify-end">
                      <button class="bg-error text-white shadow mr-2 rounded-full px-5 py-1.5" @click="deleteSpecificComponent = false">No</button>
                      <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="removeComponent()">Yes</button>
                    </div>
                  </div>
                  <hr>
                  <div class="p-4">
                    <p class="text-gray3 heading-4">Once delete cannot be undone !</p>
                  </div>
                </div>
              </div>
              <!-- <div style="position:absolute;justify-self:right;margin-top: 47px;" class="pr-4 -mt-4">
                <button v-if="pageObj.pageSections.length > 1" class=" rounded-full h-7 w-7 bg-red-500 mr-1 cursor-pointer"
                  @click="removeSection(index)"
                  >
                  <i class="fas fa-trash-alt text-white heading-4 pt-1"></i>
                </button>
              </div> -->
              <div class="pt-0 grid grid-cols-12">
              <!-- <div class="box col-span-5 p-2">
                <div class="bg-white rounded">
                  <div class="w-full">
                    <div class="" >
                      <p class="label_css">Select Component</p>
                      <div class="py-1 p-2 flex border border-gray-500 bg-white shadow rounded" @click.stop="showCompPopupBranch()" :class="ffnameErr ? 'border border-error' : ''">
                        <span class="place-self-center flex">
                          <div class="flex flex-auto flex-wrap" >
                            <input placeholder="Select Component" v-model="selectedCompVal" @focus="selectedCompVal = ''"
                            class=" h-9 cursor-pointer  focus:outline-none heading-4 text-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500" style="width: 360px">
                          </div>
                          <p v-if="displayComponentSelect" class="self-center"><i class="fas fa-chevron-up"></i></p>
                          <span v-if="!displayComponentSelect" class="self-center"><i class="fas fa-chevron-down"></i></span>
                        </span>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg" style="overflow-x: auto;width: 400px;position:" v-if="displayComponentSelect && staticComponentList.length > 0" v-click-outside="hideCompPopupBranch">
                        <div v-for="(cell, indexA) in staticComponentListDisplay" :key="indexA" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" style="overflow-wrap: anywhere !important;"
                        :class="selectedCompVal === cell.component_name ? 'bg-blue-100' : '' "
                        @click="selectStaticComponent(cell, indexA)">
                          <div class=" pl-2">
                            {{ cell.component_name }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-300 shadow-lg" style="overflow-x: auto;width: 400px;position: absolute;" v-if="displayComponentSelect && staticComponentListDisplay.length === 0">
                        <div class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" 
                       >
                          <div class="pl-2" v-if="staticComponentListDisplay.length === 0">
                            No Record Found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="p-2">
            <div>
              <button class="bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="openComponent()">Select Component</button>
            </div>
            <p v-if="selectComponentErr" class="text-error heading-6 pl-2">Select Component</p>
          </div>
            <!-- </div> -->
            <!-- <div class="p-2 pt-0 flex box col-span-12">
              <p class=" text-primary pl-2 heading-5 cursor-pointer" @click="addSection()">+ Add Section</p>
            </div> -->
          <div v-if="selectedCompVal === 'contact_form'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Contact_form @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'content_with_images'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                  <Content_with_images @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'top_banner'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                  <Top_banner @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'left_or_right_image'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                  <Left_or_right_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'heading_with_columns'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                  <Heading_with_columns @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'front_content_background_image'">
            <div v-if="componentDialog"  class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                  <Front_content_background_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'single_image'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Single_image @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'left_content_right_cards'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Left_content_with_four_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'heading_with_content'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Heading_with_content @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'heading_with_button'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Heading_with_button @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'our_services'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Our_services @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'technology_stack'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Technology_stack @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'our_work'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Our_work @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'industries'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Industries @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'brandicons'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Brand_icons @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'hover_cards'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Hover_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'developer_services'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Developer_services @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'border_cards'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Border_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'flexible_hire_model'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Flexible_hire_model @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'dedicated_wp_dev'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Dedicated_wp_dev @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'engagement_models'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Engagement_models @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'hire_table'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Hire_table @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'faqs'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Faqs @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'table_content_section'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Table_content_section @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'our_core_values'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Our_core_values @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'faqs_group'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Faqs_group @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'banner'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Banner @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'bg_button_section'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Bg_button_section @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'client_testimonials'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Client_testimonials @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'dev_methodologies'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Dev_methodologies @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'chairman_message'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Chaireman_message @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'overview'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Overview @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'multiple_heading_with_content'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Multiple_heading_with_content @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'hover_shadow_cards'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Hover_shadow_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'opportunity_with_us'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Opportunity_with_us @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'transport_dev_solu'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Transport_dev_solu @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'methodologies'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Methodologies @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'career'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <career @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'offices'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Offices @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'add_value'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Add_value @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
              </div>
            </div>
          </div>
          <div v-if="selectedCompVal === 'roundedimage_on_cards'">
            <div v-if="componentDialog" class="popup_overlay">
              <div class="custom_dialog" style="width:550px">
                <Rounded_image_on_cards @objData="getData" :componentDetailsForEdit="componentDetailsForEdit" :fieldJsonData="fieldJsonData" :componentImageUrl="comp_img_url" :componentId="selected_comp_id" @compIdImage="getSelectedCompIdImage" />
             </div>
            </div>
          </div>
          </div>
          <div class="box col-span-12 p-2 pb-0">
            <span @click="expandJSDiv()" class="flex items-center cursor-pointer">
            <span class="text-primary font-bold cursor-pointer">Page Tracking Code</span>
            <span v-if="javascriptCodeDiv === false" class="flex items-center"><i class="fas fa-angle-down text-primary h-5 w-5 ml-1"></i></span>
            <p v-else class="flex items-center"><i class="fas fa-angle-up text-primary h-5 w-5 ml-1"></i></p>
            </span>
          </div>
          <div v-if="javascriptCodeDiv">
          <div class="box col-span-12 p-2 pb-0">
            <p class="label_css">Script Tag(in Head)</p>
            <textarea
              class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
              placeholder="Script Tag(in Head)"
              outlined=""
              auto-grow=""
              v-model="pageObj.dynamicJavascriptCode.scriptTagInHead"
            ></textarea>
          </div>
          <div class="box col-span-12 p-2 pb-0">
            <p class="label_css">noScript Tag(in body)</p>
            <textarea
              class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800"
              placeholder="noScript Tag(in body)"
              outlined=""
              auto-grow=""
              v-model="pageObj.dynamicJavascriptCode.noScriptTagInBody"
            ></textarea>
          </div>
          </div>
          <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="$router.go(-1)">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveNewPage()">Save</button>
            </div>  
          </div>
          <div v-if="selectComponentDialog" class="popup_overlay">
            <div class="custom_dialog" style="width:550px">
              <SelectComponent @componentSlug="getSelectedComponentSlug" :selectComponentList="selectComponentList" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="addFieldPopup" class="popup_overlay">
        <div persistent class="custom_dialog" style="width:800px" >
          <div class="rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
            <div>
              <p class="">Add Field</p>
            </div>
            <div class="flex gap-2">
              <button class="mr-2 rounded-3xl heading-4 py-2 px-6 bg-gray-300 text-black" @click="addFieldPopup = false">Discard</button>
              <button class="mr-2 rounded-3xl heading-4 py-2 px-6 bg-success text-white" @click="saveAddedField()">Save</button>
            </div>  
          </div>
          <div class="bg-white p-2 px-4 ">
            <div class="pt-0 grid grid-cols-12">
              <div class="box p-2 col-span-6">
                <p class="label_css">Display Name</p>
                <input
                @keyup="changeUnderScore(fieldObj.fieldDisplayName, 'd2Name', '0')"
                class="outline-none text-gray-900 text-sm rounded block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="Display Name"
                :class="fnameErr ? 'border border-error' : ''"
                v-model="fieldObj.fieldDisplayName"
                >
                <p v-if="fnameErr" class="text-error heading-6">Field Display Name is Required</p>
              </div>
              <div class="box col-span-6 p-2">
                <div class="bg-white rounded">
                  <div class="w-full">
                    <div class="" >
                      <p class="label_css">Select Component</p>
                      <div class="py-1 p-2 flex border border-gray-500 bg-white shadow rounded" @click.stop="showPopupBranch()" :class="ffnameErr ? 'border border-error' : ''">
                        <span class="place-self-center flex">
                          <div class="flex flex-auto flex-wrap" >
                            <input placeholder="Select Component" v-model="fieldObj.selComponentName" @input="getComListShort(fieldObj.selComponentName)"
                            class="w-80 h-9 cursor-pointer  focus:outline-none heading-4 text-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500">
                          </div>
                          <p v-if="displayEmpSelect" class="self-center"><i class="fas fa-chevron-up"></i></p>
                          <span v-if="!displayEmpSelect" class="self-center"><i class="fas fa-chevron-down"></i></span>
                        </span>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg" style="overflow-x: auto;width: 404px;position: fixed;" v-if="displayEmpSelect && componentList.length > 0" v-click-outside="hidePopupBranch">
                        <div v-for="(cell, indexA) in componentList" :key="indexA" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" style="overflow-wrap: anywhere !important;"
                        :class="fieldObj.componentMasterId === cell.blogComponentMasterId ? 'bg-blue-100' : '' "
                        @click="selectComponent(cell, indexA)">
                          <div class=" pl-2">
                            {{ cell.componentName }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-col w-auto max-h-64 h-auto bg-white overflow-auto border border-gray-300 shadow-lg" style="overflow-x: auto;width: 370px;position: fixed;" v-if="displayEmpSelect && componentList.length === 0" v-click-outside="hidePopupBranch">
                        <div class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1" 
                       >
                          <div class="pl-2">
                            no Record found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-if="ffnameErr" class="text-error heading-6">Component Name is Required</p>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center pb-1 ">
                  <input id="default-radio-3" v-model="isRepetedCom" type="radio" value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300">Single Component</label>
                </div>
                <div class="flex items-center pb-1 col-span-6">
                  <input id="default-radio-4" v-model="isRepetedCom" type="radio" value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300">Repeatable Component</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="deleteSecConfPopup" class="popup_overlay">
        <div style="height: 120px; width: 500px;" class="custom_dialog">
          <div class="text-text1 px-4 py-3 flex justify-between items-center">
            <span class="heading-3">Are You Sure ?</span>
            <div class="flex justify-end">
              <button class="bg-error text-white shadow mr-2 rounded-full px-5 py-1.5" @click="deleteSecConfPopup = false">No</button>
              <button class="bg-primary shadow text-white rounded-full px-5 py-1.5" @click="removesectionConf()">Yes</button>
            </div>
          </div>
          <hr>
          <div class="p-4">
            <p class="text-gray3 heading-4">Once delete cannot be undone !</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import HRM from '@/View/CMS/api/HRM.js'
import Left_content_with_four_cards from './component/component_list/left_content_with_four_cards.vue'
import Heading_with_content from './component/component_list/heading_with_content.vue'
import Heading_with_button from './component/component_list/heading_with_button.vue'
import Our_services from './component/component_list/our_services.vue'
import Technology_stack from './component/component_list/technology_stack.vue'
import Our_work from './component/component_list/our_work.vue'
import Industries from './component/component_list/industries.vue'
import Brand_icons from './component/component_list/brand_icons.vue'
import Hover_cards from './component/component_list/hover_cards.vue'
import Developer_services from './component/component_list/developer_services.vue'
import Border_cards from './component/component_list/border_cards.vue'
import Flexible_hire_model from './component/component_list/flexible_hire_model.vue'
import Dedicated_wp_dev from './component/component_list/dedicated_wp_dev.vue'
import Engagement_models from './component/component_list/engagement_models.vue'
import Hire_table from './component/component_list/hire_table.vue'
import Faqs from './component/component_list/faqs.vue'
import Table_content_section from './component/component_list/table_content_section.vue'
import Our_core_values from './component/component_list/our_core_values.vue'
import Faqs_group from './component/component_list/faqs_group.vue'
import Banner from './component/component_list/banner.vue'
import Bg_button_section from './component/component_list/bg_button_section.vue'
import Client_testimonials from './component/component_list/client_testimonials.vue'
import Dev_methodologies from './component/component_list/dev_methodologies.vue'
import Chaireman_message from './component/component_list/chaireman_message.vue'
import Overview from './component/component_list/overview.vue'
import Multiple_heading_with_content from './component/component_list/multiple_heading_with_content.vue'
import Hover_shadow_cards from './component/component_list/hover_shadow_cards.vue'
import Opportunity_with_us from './component/component_list/opportunity_with_us.vue'
import Transport_dev_solu from './component/component_list/transport_dev_solu.vue'
import Methodologies from './component/component_list/methodologies.vue'
import Career from './component/component_list/career.vue'
import Offices from './component/component_list/offices.vue'
import Add_value from './component/component_list/add_value.vue'
import Rounded_image_on_cards from './component/component_list/rounded_image_on_cards.vue'
import SelectComponent from './component/selectComponent.vue'
import Single_image from './component/component_list/single_image.vue'
import Front_content_background_image from './component/component_list/front_content_background_image.vue'
import Heading_with_columns from './component/component_list/heading_with_columns.vue'
import Left_or_right_image from './component/component_list/left_or_right_image.vue'
import Top_banner from './component/component_list/top_banner.vue'
import Content_with_images from './component/component_list/content_with_images.vue'
import Contact_form from './component/component_list/contact_form.vue'

export default {
  components: {
    Left_content_with_four_cards,
    Heading_with_content,
    Heading_with_button,
    Our_services,
    Technology_stack,
    Our_work,
    Industries,
    Brand_icons,
    Hover_cards,
    Developer_services,
    Border_cards,
    Flexible_hire_model,
    Dedicated_wp_dev,
    Engagement_models,
    draggable,
    Hire_table,
    Faqs,
    Table_content_section,
    Our_core_values,
    Faqs_group,
    Banner,
    Bg_button_section,
    Client_testimonials,
    Dev_methodologies,
    Chaireman_message,
    Overview,
    Multiple_heading_with_content,
    Hover_shadow_cards,
    Opportunity_with_us,
    Transport_dev_solu,
    Methodologies,
    Career,
    Offices,
    Add_value,
    Rounded_image_on_cards,
    SelectComponent,
    Single_image,
    Front_content_background_image,
    Heading_with_columns,
    Left_or_right_image,
    Top_banner,
    Content_with_images,
    Contact_form  },
  data () {
    return {
      javascriptCodeDiv: false,
      comp_img_url: '',
      whenAddCompImgUrl: '',
      selected_comp_id: 0,
      selectComponentErr: false,
      fieldJsonData: null,
      selectComponentDialog: false,
      removeSpecificCompIndex: null,
      deleteSpecificComponent: false,
      componentDialog: false,
      fnameErr: false,
      ffnameErr: false,
      tempIndex: 0,
      deleteSecConfPopup: false,
      drag: false,
      pageDetailObj: [],
      pagnameErr: false,
      dtitlexErr: false,
      pageUrlErr: false,
      descErr: false,
      btnurlErr: false,
      btntextErr: false,
      imgurlErr: false,
      imgaltErr: false,
      isError: true,
      componentList: [],
      staticComponentList: [
        {
          component_name: "left_content_with_four_cards"
        },
        {
          component_name: "our_services"
        },
        {
          component_name: "technology_stack"
        },
        {
          component_name: "our_work"
        },
        {
          component_name: "industries"
        },
        {
          component_name: "brand_icons"
        },
        {
          component_name: "hover_cards"
        },
        {
          component_name: "developer_services"
        }
        ,{
          component_name: "border_cards"
        },
        {
          component_name: "flexible_hire_model"
        },
        {
          component_name: "dedicated_wp_dev"
        },
        {
          component_name: "engagement_models"
        },
        {
          component_name: "hire_table"
        },
        {
          component_name: "faqs"
        },
        {
          component_name: "table_content_section"
        },
        {
          component_name: "transport_dev_solu"
        },
        {
          component_name: "our_core_values"
        },
        {
          component_name: "faqs_group"
        },
        {
          component_name: "banner"
        },
        {
          component_name: "heading_with_content"
        },
        {
          component_name: "bg_button_section"
        },
        {
          component_name: "client_testimonials"
        },
        {
          component_name: "dev_methodologies"
        },
        {
          component_name: "methodologies"
        },
        {
          component_name: "chaireman_message"
        },
        {
          component_name: "overview"
        },
        {
          component_name: "multiple_heading_with_content"
        },
        {
          component_name: "hover_shadow_cards"
        },
        {
          component_name: "career"
        },
        {
          component_name: "offices"
        },
        {
          component_name: "add_value"
        },
        {
          component_name: "rounded_image_on_cards"
        },
        {
          component_name: "opportunity_with_us"
        },
        {
          component_name: "heading_with_button"
        }
      ],
      staticComponentListDisplay: [],
      allComponentsListArr: [],
      componentDetailsForEdit: null,
      selectComponentList: [],
      allCompTempIndex: null,
      isRepField: false,
      displayEmpSelect: false,
      displayComponentSelect: false,
      inputFieldList: [],
      radioButtonVal: 'newField',
      isRepetedCom: 'false',
      addFieldPopup: false,
      addFieldIndex: 0,
      selectedCompVal: '',
      fieldObj: {
        pageSectionFiledId: 0,
        componentMasterId: 0,
        selComponentName: '',
        fieldDisplayName: '',
        isRepeated: false,
        isExist: false
      },
      
      pageObj: {
        isPage: false,
        projectDetailId: 0,
        blogPageMasterId: 0,
        blogPageOpenInNewTab: false,
        isActive: true,
        blogPageDetailBtnText: '',
        blogPageUrlSlug: '',
        blogPageName: '',
        blogPageDisplayTitle: '',
        pageMetaRobots: '',
        blogPageDetailBtnUrl: '',
        blogPageImageOrVideo: '',
        blogPageImageOrVideoAlt: '',
        blogPageShortDescription: '',
        blogPageContentDetail: [],
        dynamicJavascriptCode: {
          scriptTagInHead: "",
          noScriptTagInBody: ""
        }
      }
    }
  },
  created() {
  },
  mounted () {
    this.staticComponentListDisplay = this.staticComponentList
    document.title = this.$route.params.pageMasterId > 0 ? 'Edit Blog' : 'add New Blog'
    console.log('this.$123 route.params', this.$route.params)
    console.log('this.$123 route.name', this.$route.name)
    console.log('this.$123 router', this.$router)
    if (JSON.parse(this.$route.params.pageMasterId) > 0) {
      this.getPageStructDetail()
    }
    this.getComponentList('')
    this.$root.$on("closeComponentDialog", (data) => {
      this.selectComponentDialog = false
      if (data === false) {
        // this.componentDialog = false
        this.getComponentList('')
         for (let index = 0; index < this.allComponentsListArr.length; index++) {
          if (index === this.allCompTempIndex) {
            let temp_name = this.allComponentsListArr[index].contentDetail.component_name
            // this.componentDialog = true
          if (this.allComponentsListArr[index].contentDetail.component_name === temp_name) {
            this.componentDialog = false
            this.allComponentsListArr[index].contentDetail.isCompVisible = false
          } 
          }
        }
      } else {
        this.fieldJsonData = null
        this.componentDialog = false
        for (let index = 0; index < this.allComponentsListArr.length; index++) {
          if (index === this.allCompTempIndex) {
            let temp_name = this.allComponentsListArr[index].contentDetail.component_name
            this.componentDialog = true
          if (this.allComponentsListArr[index].contentDetail.component_name === temp_name) {
            this.componentDialog = false
            this.allComponentsListArr[index].contentDetail.isCompVisible = false
          } 
          }
        }
      }
    });
  },
  watch: {
    selectedCompVal: {
      handler() {
        let filter = this.selectedCompVal.toLowerCase();
        this.staticComponentListDisplay = []
        for (let index = 0; index < this.staticComponentList.length; index++) {
            if (this.selectedCompVal.toLowerCase().indexOf(filter) > -1 === this.staticComponentList[index].component_name.toLowerCase().indexOf(filter) > -1) {
                this.staticComponentListDisplay.push(this.staticComponentList[index])
            }
        }
      }
    },
    allComponentsListArr: {
      handler() {
        if (this.allComponentsListArr.length > 0) {
          this.selectComponentErr = false
        } else {
          this.selectComponentErr = true
        }
      }
    },
    pageObj: {
      handler () {
        console.log('pageObj 123', this.pageObj)
        if (this.pageObj.blogPageName !== '') {
          this.pagnameErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageDisplayTitle !== '') {
          this.dtitlexErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageUrlSlug !== '') {
          this.pageUrlErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageShortDescription !== '') {
          this.descErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageDetailBtnUrl !== '') {
          this.btnurlErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageDetailBtnText !== '') {
          this.btntextErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageImageOrVideo !== '') {
          this.imgurlErr = false
          this.isError = false
        }
        if (this.pageObj.blogPageImageOrVideoAlt !== '') {
          this.imgaltErr = false
          this.isError = false
        }
        // for (let index = 0; index < this.pageObj.pageSections.length; index++) {
        //   if (this.pageObj.pageSections[index].sectionName !== '') {
        //     this.pageObj.pageSections[index].sectionErr = false
        //     this.isError = false
        //   }
        // }
      },
      deep: true
    },
    fieldObj: {
      handler () {
        if (this.fieldObj.fieldDisplayName !== '') {
          this.fnameErr = false
        }
        if (this.fieldObj.selComponentName !== '' && this.fieldObj.componentMasterId > 0) {
          this.ffnameErr = false
        }
      },
      deep: true
    },
    isRepetedCom: {
      handler () {
        console.log('radioButtonVal 123', this.isRepetedCom)
        if (this.isRepetedCom === 'true') {
          this.fieldObj.isRepeated = true
        } else {
          this.fieldObj.isRepeated = false
        }
      },
      deep: true
    }
  },
  methods: {
    expandJSDiv () {
        this.javascriptCodeDiv = !this.javascriptCodeDiv;
    },
    openComponent () {
      console.log("HELLO", this.componentList);
      this.selectComponentList = this.componentList
      this.selectComponentDialog = true
      this.allCompTempIndex = null
    },
    editComponentDetails (data, index, componentImageUrl) {
      this.allCompTempIndex = index
      this.comp_img_url = componentImageUrl
      console.log("data", data);
      console.log("allCompTempIndex", this.allCompTempIndex)
      for (let i = 0; i < this.allComponentsListArr.length; i++) {
        this.allComponentsListArr[i].contentDetail.isCompVisible = false
        if (this.allCompTempIndex === i) {
          this.componentDetailsForEdit = data.contentDetail
          let temp_name = this.allComponentsListArr[i].contentDetail.component_name
          this.componentDialog = true
          if (this.allComponentsListArr[i].contentDetail.component_name === temp_name) {
            this.componentDialog = false
            this.allComponentsListArr[i].contentDetail.isCompVisible = true
          } else {
            this.allComponentsListArr[i].contentDetail.isCompVisible = false
          }
        }
      }
    },
    removeEditComponentDetails (index) {
      this.deleteSpecificComponent = true
      this.removeSpecificCompIndex = index
      // this.allComponentsListArr.splice(index, 1)
      // if (this.allComponentsListArr.length === 0) {
      //   this.allCompTempIndex = null
      // }
      console.log("~~~~~~~~~~~~~~~~~", this.componentDetailsForEdit)
    },
    removeComponent () {
      this.deleteSpecificComponent = false
      this.allComponentsListArr.splice(this.removeSpecificCompIndex, 1)
      if (this.allComponentsListArr.length === 0) {
        this.allCompTempIndex = null
      }
    },
    getSelectedComponentSlug (value) {
      console.log("Component Slug", value);
      this.componentDetailsForEdit = null
      this.fieldJsonData = null
      this.componentDialog = true
      this.selectedCompVal = value
      for (let index = 0; index < this.componentList.length; index++) {
        if (this.componentList[index].componentSlug === value) {
          // this.componentList[index].fieldJson.component_name = this.componentList[index].componentSlug
          console.log("this.componentList[index]", this.componentList[index]);
          this.selected_comp_id = this.componentList[index].blogComponentMasterId
          this.comp_img_url = this.componentList[index].componentImageUrl
          console.log("this.componentList[index].fieldJson", this.componentList[index].fieldJson);
          this.fieldJsonData = this.componentList[index].fieldJson
        }
      }
    },
    getSelectedCompIdImage (CompData) {
      for (let index = 0; index < this.allComponentsListArr.length; index++) {
        if (this.allComponentsListArr[index].componentMasterId === CompData.id) {
          if (this.allComponentsListArr[index].blogComponentImageUrl === undefined) {
            // this.whenAddCompImgUrl = CompData.imageUrl
            this.allComponentsListArr[index]['blogComponentImageUrl'] = CompData.imageUrl
          }
        }
      }
    },
    getData (value) {
      console.log("Taken data from child component", value)
      this.componentDialog = false
      let componentId = 0;
      if (value !== undefined) {
        for (let index = 0; index < this.componentList.length; index++) {
          if (this.componentList[index].componentSlug === value.component_name) {
            componentId = this.componentList[index].blogComponentMasterId
          }
        }
      }
      if (value !== undefined) {
        value["isCompVisible"] = false
        if (this.allCompTempIndex === null) {
          let obj = {
            blogPageSectionMappingId: 0,
            componentMasterId: componentId,
            contentDetail: value,
            displayOrder: 0
          }
          this.allComponentsListArr.push(obj)
        } else {
          // for (let index = 0; index < this.allComponentsListArr.length; index++) {
            if (this.allCompTempIndex !== null) {
              console.log("111111111111111")
              // if (this.componentDialog !== true) {
                this.allComponentsListArr[this.allCompTempIndex].contentDetail = value
              // } 
              } else {
                let obj = {
                  blogPageSectionMappingId: 0,
                  componentMasterId: componentId,
                  contentDetail: value,
                  displayOrder: 0
                }
                this.allComponentsListArr.push(obj)
            // }
          }
        }
      }
      console.log("this.allComponentsListArr", this.allComponentsListArr);
    },
    getComListShort (value) {
      this.getComponentList(value)
    },
    changeUnderScore (value, from, index) {
      if (from === 'sName') {
        this.pageObj.pageSections[index].sectionName = value.replace(/ /g,"_")
      } else if (from === 'dName') {
        this.fieldObj.fieldDisplayName = value.replace(/ /g,"_")
      } else if (from === 'd2Name') {
        this.fieldObj.fieldDisplayName = value.replace(/ /g,"_")
      }
    },
    showCompPopupBranch() {
      this.displayComponentSelect = true
    },
    hideCompPopupBranch() {
      this.displayComponentSelect = false
    },
    selectStaticComponent(val, listIndex) {
      this.componentDetailsForEdit = null
      this.componentDialog = true
      console.log('val', val.component_name)
      console.log('index', listIndex)
      this.selectedCompVal = val.component_name
      this.displayComponentSelect = false
    },
    showPopupBranch() {
      this.displayEmpSelect = true
    },
    hidePopupBranch() {
      this.displayEmpSelect = false
    },
    dragArray () {
      this.drag = false
    },
    selectComponent(val, listIndex) {
      console.log('val', val)
      console.log('index', listIndex)
      this.fieldObj.componentMasterId = val.blogComponentMasterId
      this.fieldObj.selComponentName = val.componentName
      this.displayEmpSelect = false
    },
    saveAddedField () {
      console.log('fieldObj', this.fieldObj)
      if (this.fieldObj.fieldDisplayName === '') {
        this.fnameErr= true
      }
      if (this.fieldObj.selComponentName === '' && this.fieldObj.componentMasterId === 0) {
        this.ffnameErr = true
      }
      if (!this.ffnameErr && !this.fnameErr) {
        if (this.pageObj.pageSections[this.addFieldIndex].pageSectionFields === null) {
          this.pageObj.pageSections[this.addFieldIndex].pageSectionFields = []
        }
        if (this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.length === 1) {
          let value = this.pageObj.pageSections[this.addFieldIndex].pageSectionFields[0]
          if (value.componentMasterId === 0 && value.fieldDisplayName === '') {
            this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.splice(0, 1)
          }
        }
        this.pageObj.pageSections[this.addFieldIndex].pageSectionFields.push(
          {
            blogPageSectionFiledId: this.fieldObj.pageSectionFiledId,
            componentMasterId: this.fieldObj.componentMasterId,
            fieldDisplayName: this.fieldObj.fieldDisplayName,
            selComponentName: this.fieldObj.selComponentName,
            isRepeated: this.fieldObj.isRepeated,
            isExist: this.fieldObj.isExist
          }
        )
        this.fieldObj.pageSectionFiledId = 0
        this.fieldObj.componentMasterId = 0
        this.fieldObj.fieldDisplayName = ''
        this.fieldObj.selComponentName = ''
        this.fieldObj.isRepeated = false
        this.isRepetedCom = 'false'
        this.radioButtonVal = 'newField'
        this.pageObj.pageSections[this.addFieldIndex].isShow = true
        this.addFieldPopup = false
      }
    },
    addField(index) {
      this.addFieldIndex = index
      this.addFieldPopup = true
      console.log('add field')
    },
    addSection() {
      console.log('add section')
      this.pageObj.pageSections.push(
        {
          blogPageSectionMappingId: 0,
          sectionName: '',
          sectionErr: false,
          displayOrder: 0,
          isShow: false,
          pageSectionFields: [
            {
              blogPageSectionFiledId: 0,
              componentMasterId: 0,
              fieldDisplayName: '',
              selComponentName: '',
              isRepeated: false
            }
          ]
        }
      )
    },
    removeSection(index) {
      this.tempIndex = index
      this.deleteSecConfPopup = true
      // if (this.pageObj.pageSections.length !== 1) {
      //   this.pageObj.pageSections.splice(index, 1)
      // }
    },
    removesectionConf() {
      this.deleteSecConfPopup = false
      if (this.pageObj.pageSections.length !== 1) {
        this.pageObj.pageSections.splice(this.tempIndex, 1)
      }
    },
    removeField(childIndex, index) {
      this.pageObj.pageSections[index].pageSectionFields.splice(childIndex, 1)
    },
    getPageStructDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRM.BlogSpecificDetail(
        JSON.parse(this.$route.params.pageMasterId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          console.log("response pageDetailObj", this.pageDetailObj)
          console.log("response pageObj", this.pageObj)
          this.pageObj.blogPageName = this.pageDetailObj.blogPageName
          this.pageObj.blogPageDetailBtnText = this.pageDetailObj.blogPageDetailBtnText
          this.pageObj.blogPageDetailBtnUrl = this.pageDetailObj.blogPageDetailBtnUrl
          this.pageObj.blogPageDisplayTitle = this.pageDetailObj.blogPageDisplayTitle
          this.pageObj.blogPageImageOrVideo = this.pageDetailObj.blogPageImageOrVideo
          this.pageObj.blogPageImageOrVideoAlt = this.pageDetailObj.blogPageImageOrVideoAlt
          this.pageObj.blogPageMasterId = this.pageDetailObj.blogPageMasterId
          this.pageObj.blogPageOpenInNewTab = this.pageDetailObj.blogPageOpenInNewTab
          this.pageObj.blogPageShortDescription = this.pageDetailObj.blogPageShortDescription
          this.pageObj.blogPageUrlSlug = this.pageDetailObj.blogPageUrlSlug
          this.pageObj.isActive = this.pageDetailObj.isActive
          this.pageObj.projectDetailId = this.pageDetailObj.projectDetailId
          this.allComponentsListArr = this.pageDetailObj.blogPageContentDetail
          if (this.pageDetailObj.scriptTagInHead !== '' || this.pageDetailObj.noScriptTagInBody !== '') {
            this.javascriptCodeDiv = true
          } else {
            this.javascriptCodeDiv = false
          }
          this.pageObj.dynamicJavascriptCode.scriptTagInHead = this.pageDetailObj.scriptTagInHead;
          this.pageObj.dynamicJavascriptCode.noScriptTagInBody = this.pageDetailObj.noScriptTagInBody;
          // this.pageObj.pageSections = this.pageDetailObj.pageSections === null ? [] : this.pageDetailObj.pageSections

          // for (let index = 0; index < this.pageObj.pageSections.length; index++) {
          //   this.pageObj.pageSections[index].isShow = true
          //   if (this.pageObj.pageSections[index].pageSectionFields !== null) {
          //     for (let k = 0; k < this.pageObj.pageSections[index].pageSectionFields.length; k++) {
          //       this.pageObj.pageSections[index].pageSectionFields[k].selComponentName = this.pageObj.pageSections[index].pageSectionFields[k].componentName
          //       if (this.pageObj.pageSections[index].pageSectionFields[k].componentMasterId > 0) {
          //         this.pageObj.pageSections[index].pageSectionFields[k].isExist = true
          //       } else {
          //         this.pageObj.pageSections[index].pageSectionFields[k].isExist = false
          //       }
          //     }
          //   }
          // }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    saveNewPage() {
      console.log('this.pageObj',  this.pageObj)
      this.$root.$emit("callFormHandler")
      this.getData();
      if (this.allComponentsListArr.length > 0) {
        this.selectComponentErr = false
        this.isError = false
      } else {
        this.selectComponentErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageName === '') {
        this.pagnameErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageDisplayTitle === '') {
        this.dtitlexErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageUrlSlug === '') {
        this.pageUrlErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageShortDescription === '') {
        this.descErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageDetailBtnUrl === '') {
        this.btnurlErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageDetailBtnText === '') {
        this.btntextErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageImageOrVideo === '') {
        this.imgurlErr = true
        this.isError = true
      }
      if (this.pageObj.blogPageImageOrVideoAlt === '') {
        this.imgaltErr = true
        this.isError = true
      }
      // for (let index = 0; index < this.pageObj.pageSections.length; index++) {
      //   if (this.pageObj.pageSections[index].sectionName === '') {
      //     this.pageObj.pageSections[index].sectionErr = true
      //     this.isError = true
      //   }
      // }
      this.pageObj.blogPageMasterId = JSON.parse(this.$route.params.pageMasterId)
      this.pageObj.projectDetailId = JSON.parse(this.$route.params.projectId)
      console.log('save 123 123 pageObj', this.pageObj)
      if (this.isError === false) {
        for (let index = 0; index < this.allComponentsListArr.length; index++) {
          this.allComponentsListArr[index].contentDetail = JSON.stringify(this.allComponentsListArr[index].contentDetail)
        }
        this.pageObj.blogPageContentDetail = this.allComponentsListArr
        console.log('SuccessFully', this.pageObj)
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        HRM.BlogSaveApi(
          this.pageObj,
          response => {
            console.log("response", response.Data)
            this.$router.go(-1);
            // this.$router.push({name: 'BlogSpecificPageDetail', params: {projectId: JSON.parse(this.$route.params.projectId), pageMasterId: response.Data}})
            // this.$router.push({name: 'specificProject', params: {projectId: JSON.parse(this.$route.params.projectId)}})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getComponentList (value) {
      HRM.BlogComponentList(
        0,
        100,
        value,
        'addedDate',
        'DESC',
        true,
        JSON.parse(this.$route.params.projectId),
        response => {
          console.log("response", response.Data)
          this.componentList = response.Data.tableRow === null ? [] : response.Data.tableRow
        },
        error => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
    this.$root.$off('closeComponentDialog')
  }
}
</script>
<style scoped>
.list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
}

::placeholder {
  font-size: 16px;
}
</style>