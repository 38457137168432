<template>
  <div v-if="dialog" class="popup_overlay">
  <div class="custom_dialog_comp bg-white" style="width:100%; height: 100%">
    <div class="cust_card">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">Select Component</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <!-- <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addComponent()">Save</button> -->
        </div>  
      </div>
      <div class="p-0 bg-white" >
        <div class="bg-white p-2">
            <div class="grid grid-cols-10">
            <div v-for="(data, index) in selectComponentList" :key="index" class="p-2 col-span-2">
                <!-- <div class=" p-2 border-2" @click="selectComponent(data.componentSlug)"> -->
                    <img style="padding: 1px"  class="box border-1 rounded-lg" :src="data.componentImageUrl" :alt="data.componentImageUrl" @click="selectComponent(data.componentSlug)" />
                <!-- </div> -->
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  components: {
  },
  props: ["selectComponentList"],
  data () {
    return {
      dialog: true
    }
  },
  validations: {
  },
  computed: {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    selectComponent (slug) {
        console.log("SLUG", slug);
        this.$emit('componentSlug', slug)
        this.$root.$emit('closeComponentDialog', false)
    },
    closeDetails () {
      this.$root.$emit('closeComponentDialog', true)
    }
  }
}
</script>
<style scoped>
.buttons .button {
  margin-bottom: 0rem;
}
.box {
    /* width: 250px; */
    /* height: 300px; */
    /* background: orange; */
    margin: 0px 10px;
    transition: 1s;
}
.box:hover{
    transform: scale(1.2);
    background: #ccc;
    z-index: 2;
    box-shadow: 4px 4px 4px #ccc;
    cursor: pointer;
}
</style>

