<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">heading_with_content</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full">
          <div>
            <div class="p-2 pb-2 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.heading"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
              </div>
              <div v-if="!descriptionEditor" class="box col-span-12 p-2 pb-0" :class="componentDetailsForEdit !== null ? '' : 'mt-4'">
                <p class="label_css font-bold cursor-pointer" style="color: #1976D3" @click="openDescription()">{{componentDetailsForEdit !== null ? "+ Edit Description" : "+ Add Description"}}</p>
                <p v-if="componentDetailsForEdit !== null" v-html="fieldJsonData.description"></p>
                <p v-if="desc_err" class="text-error heading-6">description is required</p>
              </div>
              <div v-if="descriptionEditor" class="box col-span-12 p-2 pb-0" v-click-outside="closeDescription">
                <p class="label_css">description</p>
                <editor v-model="fieldJsonData.description"
                :class="desc_err ? 'border border-error' : ''"
                id="one"
                style="margin-top:4px;"
                ></editor>
                <p v-if="desc_err" class="text-error heading-6">description is required</p>
              </div>
              <div class="col-span-12 mt-2 p-2 pb-0">
                <p class="label_css">text_align_center</p>
                <div class="flex items-center pb-1 ">
                  <input id="default-radio-3" v-model="fieldJsonData.text_align_center" type="radio" :value="false" name="default-radio 3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300">No</label>
                </div>
                <div class="flex items-center pb-1 col-span-6">
                  <input id="default-radio-4" v-model="fieldJsonData.text_align_center" type="radio" :value="true" name="default-radio 4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                  <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300">Yes</label>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
import Editor from '../../../../../../components/editor.vue'
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    Editor
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      appendValue: '',
      desc_err: false,
      heading_err: false,
      isError: true,
      descriptionEditor: false,
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   heading: "",
      //   description: "",
      // }
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!HeadWithContent", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
  },
  watch: {
    fieldJsonData: {
      handler () {
        console.log('fieldJsonData 123', this.fieldJsonData)
        if (this.fieldJsonData.description !== '') {
          this.desc_err = false
          this.isError = false
        }
        // if (this.fieldJsonData.heading !== '') {
        //   this.heading_err = false
        //   this.isError = false
        // }
      },
      deep: true
    }
  },
  methods: {
    openDescription () {
      this.descriptionEditor = true
    },
    closeDescription () {
      this.descriptionEditor = false
    },
    addTags (addValue) {
      this.appendValue = addValue
    },
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
      if (this.fieldJsonData.description === '') {
        this.desc_err = true
        this.isError = true
      }
      // if (this.fieldJsonData.heading === '') {
      //   this.heading_err = true
      //   this.isError = true
      // }
      if (this.isError === false) {
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
      }
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>