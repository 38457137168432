<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">heading_with_columns</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div>
            <div class="p-2 pb-2 grid grid-cols-12">
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.heading"
                :class="heading_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
                <p v-if="heading_err" class="text-error heading-6">heading is required</p>
              </div>
              <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">col_1</p>
                <textarea v-model="fieldJsonData.col_1"
                :class="col_1_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="col_1"
                ></textarea>
                <p v-if="col_1_err" class="text-error heading-6">col_1 is required</p>
              </div> -->
              <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">heading_style</p>
                <input v-model="fieldJsonData.heading_style"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading_style"
                >
              </div> -->
               <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">col_2</p>
                <textarea v-model="fieldJsonData.col_2"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="col_2"
                ></textarea>
              </div> -->
               <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">col_3</p>
                <textarea v-model="fieldJsonData.col_3"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="col_3"
                ></textarea>
              </div> -->
               <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">col_4</p>
                <textarea v-model="fieldJsonData.col_4"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-28 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="col_4"
                ></textarea>
              </div> -->
            </div>
          <!-- <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveData()">Save</button>
            </div>  
          </div> -->
        </div>
        <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">col_1</p>
           <div v-for="(data, index) in fieldJsonData.col_1_array" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">col_name</p>
                    <input v-model="data.col_name"
                    :class="colOneErrorArray[index].col_name_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="col_name"
                    />
                    <p v-if="colOneErrorArray[index].col_name_err" class="text-error heading-6">heading is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">hyperlink_url</p>
                    <input v-model="data.col_hyperlink_url"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"
                    placeholder="hyperlink_url"
                    />
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">is_open_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-1-${index}`" v-model="data.is_open_new_tab" type="radio" :value="false" :name="`default-radio-1-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-1-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-2-${index}`" v-model="data.is_open_new_tab" type="radio" :value="true" :name="`default-radio-2-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-2-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
               <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeColOneDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.col_1_array.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addColOneDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.col_1_array.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">col_2</p>
           <div v-for="(data, index) in fieldJsonData.col_2_array" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">col_name</p>
                    <input v-model="data.col_name"
                    :class="colTwoErrorArray[index].col_name_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="col_name"
                    />
                    <p v-if="colTwoErrorArray[index].col_name_err" class="text-error heading-6">heading is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">hyperlink_url</p>
                    <input v-model="data.col_hyperlink_url"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"
                    placeholder="hyperlink_url"
                    />
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">is_open_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-3-${index}`" v-model="data.is_open_new_tab" type="radio" :value="false" :name="`default-radio-3-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-3-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-4-${index}`" v-model="data.is_open_new_tab" type="radio" :value="true" :name="`default-radio-4-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-4-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
               <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeColTwoDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.col_2_array.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addColTwoDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.col_2_array.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">col_3</p>
           <div v-for="(data, index) in fieldJsonData.col_3_array" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">col_name</p>
                    <input v-model="data.col_name"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="col_name"
                    />
                    <p v-if="colThreeErrorArray[index].col_name_err" class="text-error heading-6">heading is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">hyperlink_url</p>
                    <input v-model="data.col_hyperlink_url"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"
                    placeholder="hyperlink_url"
                    />
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">is_open_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-5-${index}`" v-model="data.is_open_new_tab" type="radio" :value="false" :name="`default-radio-5-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-5-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-6-${index}`" v-model="data.is_open_new_tab" type="radio" :value="true" :name="`default-radio-6-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-6-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
               <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeColThreeDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.col_3_array.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addColThreeDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.col_3_array.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">col_4</p>
           <div v-for="(data, index) in fieldJsonData.col_4_array" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">col_name</p>
                    <input v-model="data.col_name"
                    :class="colFourErrorArray[index].col_name_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="col_name"
                    />
                    <p v-if="colFourErrorArray[index].col_name_err" class="text-error heading-6">heading is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">hyperlink_url</p>
                    <input v-model="data.col_hyperlink_url"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800"
                    placeholder="hyperlink_url"
                    />
                </div>
                <div class="col-span-12 mt-2 p-2 pb-0">
                    <p class="label_css">is_open_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input :id="`default-radio-7-${index}`" v-model="data.is_open_new_tab" type="radio" :value="false" :name="`default-radio-7-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-7-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input :id="`default-radio-8-${index}`" v-model="data.is_open_new_tab" type="radio" :value="true" :name="`default-radio-8-${index}`" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label :for="`default-radio-8-${index}`" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
               <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeColFourDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.col_4_array.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addColFourDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.col_4_array.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <div  class="mt-4" style="border: 1px solid gray"></div>
      </div>
      <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
// import Editor from '../../../../../../components/editor.vue'
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    // Editor
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      heading_err: false,
      col_1_err: false,
      isError: true,
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   heading: "",
      //   col_1: "",
      //   col_2: "",
      //   col_3: "",
      //   col_4: "",
      //   cta_url: "",
      //   cta_in_new_tab: false,
      //   is_visible: true
      // }
      colOneErrorArray: [
        {
          col_name_err: false
        }
      ],
      colTwoErrorArray: [
        {
          col_name_err: false
        }
      ],
      colThreeErrorArray: [
        {
          col_name_err: false
        }
      ],
      colFourErrorArray: [
        {
          col_name_err: false
        }
      ]
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("!!!!!!!!!!!!!", this.fieldJsonData)
      for (let index = 0; index < this.fieldJsonData.col_1_array.length; index++) {
        let obj = {
          col_name_err: false
        }
        this.colOneErrorArray.push(obj)
      }
      for (let index = 0; index < this.fieldJsonData.col_2_array.length; index++) {
        let obj = {
          col_name_err: false
        }
        this.colTwoErrorArray.push(obj)
      }
      for (let index = 0; index < this.fieldJsonData.col_3_array.length; index++) {
        let obj = {
          col_name_err: false
        }
        this.colThreeErrorArray.push(obj)
      }
      for (let index = 0; index < this.fieldJsonData.col_4_array.length; index++) {
        let obj = {
          col_name_err: false
        }
        this.colFourErrorArray.push(obj)
      }
    }
  },
  mounted () {
    console.log("fieldJsonData", this.fieldJsonData)
  },
  watch: {
    fieldJsonData: {
      handler () {
        console.log('fieldJsonData 123', this.fieldJsonData)
        if (this.fieldJsonData.col_1 !== '') {
          this.col_1_err = false
          this.isError = false
        }
        if (this.fieldJsonData.heading !== '') {
          this.heading_err = false
          this.isError = false
        }
        for (let index = 0; index < this.fieldJsonData.col_1_array.length; index++) {
          for (let i = 0; i < this.colOneErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_1_array[index].col_name !== '') {
                this.colOneErrorArray[i].col_name_err = false
                this.isError = false
              }
            }
          }
        }
        for (let index = 0; index < this.fieldJsonData.col_2_array.length; index++) {
          for (let i = 0; i < this.colTwoErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_2_array[index].col_name !== '') {
                this.colTwoErrorArray[i].col_name_err = false
                this.isError = false
              }
            }
          }
        }
        for (let index = 0; index < this.fieldJsonData.col_3_array.length; index++) {
          for (let i = 0; i < this.colThreeErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_3_array[index].col_name !== '') {
                this.colThreeErrorArray[i].col_name_err = false
                this.isError = false
              }
            }
          }
        }
        for (let index = 0; index < this.fieldJsonData.col_4_array.length; index++) {
          for (let i = 0; i < this.colFourErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_4_array[index].col_name !== '') {
                this.colFourErrorArray[i].col_name_err = false
                this.isError = false
              }
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    addColOneDetails () {
      this.fieldJsonData.col_1_array.push({
        col_hyperlink_url: "",
        col_name: "",
        is_open_new_tab: false,
      })
      console.log("addddddd", this.fieldJsonData.card_list);
      this.colOneErrorArray.push(
        {
          col_name_err: false
        }
      )
    },
    removeColOneDetails (index) {
      if (this.fieldJsonData.col_1_array.length !== 1) {
        this.fieldJsonData.col_1_array.splice(index, 1)
      }
    },
    addColTwoDetails () {
      this.fieldJsonData.col_2_array.push({
        col_hyperlink_url: "",
        col_name: "",
        is_open_new_tab: false,
      })
      console.log("addddddd", this.fieldJsonData.card_list);
      this.colTwoErrorArray.push(
        {
          col_name_err: false
        }
      )
    },
    removeColTwoDetails (index) {
      if (this.fieldJsonData.col_2_array.length !== 1) {
        this.fieldJsonData.col_2_array.splice(index, 1)
      }
    },
    addColThreeDetails () {
      this.fieldJsonData.col_3_array.push({
        col_hyperlink_url: "",
        col_name: "",
        is_open_new_tab: false,
      })
      console.log("addddddd", this.fieldJsonData.card_list);
      this.colThreeErrorArray.push(
        {
          col_name_err: false
        }
      )
    },
    removeColThreeDetails (index) {
      if (this.fieldJsonData.col_3_array.length !== 1) {
        this.fieldJsonData.col_3_array.splice(index, 1)
      }
    },
    addColFourDetails () {
      this.fieldJsonData.col_4_array.push({
        col_hyperlink_url: "",
        col_name: "",
        is_open_new_tab: false,
      })
      console.log("addddddd", this.fieldJsonData.card_list);
      this.colFourErrorArray.push(
        {
          col_name_err: false
        }
      )
    },
    removeColFourDetails (index) {
      if (this.fieldJsonData.col_4_array.length !== 1) {
        this.fieldJsonData.col_4_array.splice(index, 1)
      }
    },
    saveData() {
      if (this.fieldJsonData.heading === '') {
        this.heading_err = true
        this.isError = true
      }
      for (let index = 0; index < this.fieldJsonData.col_1_array.length; index++) {
          for (let i = 0; i < this.colOneErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_1_array[index].col_name === '') {
                this.colOneErrorArray[i].col_name_err = true
                this.isError = true
              }
            }
          }
      }
      for (let index = 0; index < this.fieldJsonData.col_2_array.length; index++) {
          for (let i = 0; i < this.colTwoErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_2_array[index].col_name === '') {
                this.colTwoErrorArray[i].col_name_err = true
                this.isError = true
              }
            }
          }
      }
      for (let index = 0; index < this.fieldJsonData.col_3_array.length; index++) {
          for (let i = 0; i < this.colThreeErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_3_array[index].col_name === '') {
                this.colThreeErrorArray[i].col_name_err = true
                this.isError = true
              }
            }
          }
      }
      for (let index = 0; index < this.fieldJsonData.col_4_array.length; index++) {
          for (let i = 0; i < this.colFourErrorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.col_4_array[index].col_name === '') {
                this.colFourErrorArray[i].col_name_err = true
                this.isError = true
              }
            }
          }
      }
      if (this.isError === false) {
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
      }
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>