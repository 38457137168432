<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">overview</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div>
            <div class="p-2 pb-2 grid grid-cols-12">
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">heading</p>
                <textarea v-model="fieldJsonData.heading"
                :class="heading_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading"
                ></textarea>
                <p v-if="heading_err" class="text-error heading-6">heading is required</p>
              </div>
              <!-- <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">heading_style_left</p>
                <input v-model="fieldJsonData.heading_style_left"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading_style_left"
                >
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">heading_style_right</p>
                <input v-model="fieldJsonData.heading_style_right"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="heading_style_right"
                >
              </div> -->
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">img_url</p>
                <input v-model="fieldJsonData.img_url"
                :class="img_url_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="img_url"
                >
                <p v-if="img_url_err" class="text-error heading-6">img_url is required</p>
              </div>
               <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">img_alt</p>
                <input v-model="fieldJsonData.img_alt"
                :class="img_alt_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="img_alt"
                >
                <p v-if="img_alt_err" class="text-error heading-6">img_alt is required</p>
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">right_heading</p>
                <textarea v-model="fieldJsonData.right_heading"
                :class="right_heading_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="right_heading"
                ></textarea>
                <p v-if="right_heading_err" class="text-error heading-6">right_heading is required</p>
              </div>
               <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">left_content</p>
                <textarea v-model="fieldJsonData.left_content"
                :class="left_content_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="left_content"
                ></textarea>
                <p v-if="left_content_err" class="text-error heading-6">left_content is required</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">right_content</p>
                <editor v-model="fieldJsonData.right_content"
                :class="right_content_err ? 'border border-error' : ''"
                placeholder="right_content"
                ></editor>
                <p v-if="right_content_err" class="text-error heading-6">right_content is required</p>
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">cta_text</p>
                <input v-model="fieldJsonData.cta_text"
                :class="cta_text_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="cta_text"
                >
                <p v-if="cta_text_err" class="text-error heading-6">cta_text is required</p>
              </div>
              <div class="box col-span-6 p-2 pb-0">
                <p class="label_css">cta_url</p>
                <input v-model="fieldJsonData.cta_url"
                :class="cta_url_err ? 'border border-error' : ''"
                class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                placeholder="cta_url"
                >
                <p v-if="cta_url_err" class="text-error heading-6">cta_url is required</p>
              </div>
              <div class="col-span-6 mt-2 p-2 pb-0">
                    <p class="label_css">cta_in_new_tab</p>
                    <div class="flex items-center pb-1 ">
                    <input id="default-radio-3" v-model="fieldJsonData.cta_in_new_tab" type="radio" :value="false" name="default-radio-3" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label for="default-radio-3" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">No</label>
                    </div>
                    <div class="flex items-center pb-1 col-span-6">
                    <input id="default-radio-4" v-model="fieldJsonData.cta_in_new_tab" type="radio" :value="true" name="default-radio-4" class="w-5 h-5 text-primary bg-gray-100 border-gray-300 outline-none cursor-pointer">
                    <label for="default-radio-4" class="ml-2 heading-4 text-text2  dark:text-gray-300 cursor-pointer">Yes</label>
                    </div>
               </div>
            </div>
          <!-- <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveData()">Save</button>
            </div>  
          </div> -->
        </div>
      </div>
      <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
    </div>
  </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
import Editor from '../../../../../../components/editor.vue'
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    Editor
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      heading_err: false,
      right_heading_err: false,
      img_url_err: false,
      img_alt_err: false,
      left_content_err: false,
      right_content_err: false,
      cta_text_err: false,
      cta_url_err: false,
      isError: true,
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   heading: "",
      //   heading_style_left: null,
      //   heading_style_right: null,
      //   img_url: "",
      //   img_alt: "",
      //   left_content: "",
      //   right_heading: "",
      //   right_content: "",
      //   cta_in_new_tab: false,
      //   cta_text: "",
      //   cta_url: ""
      // }
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
    }
  },
  mounted () {
  },
  watch: {
    fieldJsonData: {
      handler () {
        console.log('fieldJsonData 123', this.fieldJsonData)
        if (this.fieldJsonData.heading !== '') {
          this.heading_err = false
          this.isError = false
        }
        if (this.fieldJsonData.right_heading !== '') {
          this.right_heading_err = false
          this.isError = false
        }
        if (this.fieldJsonData.img_url !== '') {
          this.img_url_err = false
          this.isError = false
        }
        if (this.fieldJsonData.img_alt !== '') {
          this.img_alt_err = false
          this.isError = false
        }
        if (this.fieldJsonData.left_content !== '') {
          this.left_content_err = false
          this.isError = false
        }
        if (this.fieldJsonData.right_content !== '') {
          this.right_content_err = false
          this.isError = false
        }
        if (this.fieldJsonData.cta_text !== '') {
          this.cta_text_err = false
          this.isError = false
        }
        if (this.fieldJsonData.cta_url !== '') {
          this.cta_url_err = false
          this.isError = false
        }
      },
      deep: true
    }
  },
  methods: {
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
      if (this.fieldJsonData.heading === '') {
        this.heading_err = true
        this.isError = true
      }
      if (this.fieldJsonData.right_heading === '') {
        this.right_heading_err = true
        this.isError = true
      }
      if (this.fieldJsonData.img_url === '') {
          this.img_url_err = true
          this.isError = true
      }
      if (this.fieldJsonData.img_alt === '') {
          this.img_alt_err = true
          this.isError = true
        }
      if (this.fieldJsonData.left_content === '') {
          this.left_content_err = true
          this.isError = true
      }
      if (this.fieldJsonData.right_content === '') {
          this.right_content_err = true
          this.isError = true
      }
      if (this.fieldJsonData.cta_text === '') {
          this.cta_text_err = true
          this.isError = true
      }
      if (this.fieldJsonData.cta_url === '') {
          this.cta_url_err = true
          this.isError = true
      }
      if (this.isError === false) {
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
      }
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>