<template>
<div class="popup_overlay">
  <div class="custom_dialog_comp bg-white h-full" style="width:100%">
    <div class="cust_card_comp">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">brandicons</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="saveData()">Save</button>
        </div>  
      </div>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-2 gap-x-2">
        <div class="box bg-white h-full cust_card">
          <div class="py-2 px-1 grid  gap-x-2">
            <p class="label_css pl-2 font-bold">card_list</p>
           <div v-for="(data, index) in fieldJsonData.card_list" :key="index" class="p-2 pb-0 grid grid-cols-12">
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">image_url</p>
                    <input v-model="data.image_url"
                    :class="errorArray[index].image_url_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="image_url"
                    >
                    <p v-if="errorArray[index].image_url_err" class="text-error heading-6">image_url is required</p>
                </div>
                <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">image_alt</p>
                    <input v-model="data.image_alt"
                    :class="errorArray[index].image_alt_err ? 'border border-error' : ''"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="image_alt"
                    >
                    <p v-if="errorArray[index].image_alt_err" class="text-error heading-6">image_alt is required</p>
                </div>
                <!-- <div class="box col-span-6 p-2 pb-0">
                    <p class="label_css">img_group_slug</p>
                    <input v-model="data.img_group_slug"
                    class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                    placeholder="img_group_slug"
                    >
                </div> -->
                <div class="box col-span-12 p-1 mt-3">
                <div class="flex justify-end">
                  <button v-if="index !== 0" class=" rounded-full h-8 w-8 bg-error  mr-1"
                    @click="removeCardDetails(index)"
                    >
                    <i class="fas fa-minus text-white heading-3"></i>
                  </button>
                  <button v-if="index === fieldJsonData.card_list.length - 1" class=" rounded-full h-8 w-8 bg-primary"
                  @click="addCardDetails()"
                    >
                    <i class="fas fa-plus text-white heading-3"></i>
                  </button>
                </div>
              </div>
                <div v-if="fieldJsonData.card_list.length - 1 !== index" class="mt-4 col-span-12" style="border: 1px solid gray; border-style: dashed"></div>
             </div>
          </div>
          <!-- <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveData()">Save</button>
            </div>  
          </div> -->
        </div>
        <div>
        <img style="padding: 1px"  class="box border-1 shadow-lg rounded-lg" :src="componentImageUrl" :alt="componentImageUrl" />
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</template>
<script>
// import draggable from 'vuedraggable'
// import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
    // draggable,
  },
  props: ['componentDetailsForEdit', 'fieldJsonData', 'componentImageUrl', 'componentId'],
  data () {
    return {
      isError: true,
      // fieldJsonData: {
      //   component_name: "fieldJsonData",
      //   card_list: [
      //       {
      //       img_group_slug: null,
      //       image_url: "",
      //       image_url_err: false,
      //       image_alt: "",
      //       image_alt_err: false,
      //       },
      //       {
      //       img_group_slug: null,
      //       image_url: "",
      //       image_url_err: false,
      //       image_alt: "",
      //       image_alt_err: false,
      //       },
      //       {
      //       img_group_slug: null,
      //       image_url: "",
      //       image_url_err: false,
      //       image_alt: "",
      //       image_alt_err: false,
      //       },
      //       {
      //       img_group_slug: null,
      //       image_url: "",
      //       image_url_err: false,
      //       image_alt: "",
      //       image_alt_err: false,
      //       },
      //       {
      //       img_group_slug: null,
      //       image_url: "",
      //       image_url_err: false,
      //       image_alt: "",
      //       image_alt_err: false,
      //       }
      //   ]
      // }
      errorArray: [
        {
          image_url_err: false,
          image_alt_err: false
        }
      ]
    }
  },
  created() {
    if (this.componentDetailsForEdit !== null) {
      console.log("componentDetailsForEdit!!!!!!!!!!!fieldJsonData", this.componentDetailsForEdit)
      this.fieldJsonData = this.componentDetailsForEdit
      console.log("this.fieldJsonData", this.fieldJsonData)
      for (let index = 0; index < this.fieldJsonData.card_list.length; index++) {
        let obj = {
          image_url_err: false,
          image_alt_err: false
        }
        this.errorArray.push(obj)
      }
    }
  },
  mounted () {
  },
  watch: {
    fieldJsonData: {
      handler () {
        console.log('fieldJsonData 123', this.fieldJsonData)
        for (let index = 0; index < this.fieldJsonData.card_list.length; index++) {
          for (let i = 0; i < this.errorArray.length; i++) {
            if (index === i) {
              if (this.fieldJsonData.card_list[index].image_url !== '') {
                this.errorArray[i].image_url_err = false
                this.isError = false
              }
              if (this.fieldJsonData.card_list[index].image_alt !== '') {
                this.errorArray[i].image_alt_err = false
                this.isError = false
              }
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    addCardDetails () {
      this.fieldJsonData.card_list.push({
        img_group_slug: null,
        image_url: "",
        image_alt: ""
      })
      console.log("addddddd", this.fieldJsonData.card_list);
      this.errorArray.push(
        {
          image_url_err: false,
          image_alt_err: false
        }
      )
    },
    removeCardDetails (index) {
      if (this.fieldJsonData.card_list.length !== 1) {
        this.fieldJsonData.card_list.splice(index, 1)
      }
      console.log("removeeeeeee", this.fieldJsonData.card_list);
    },
    saveData() {
      console.log("componentDetailsForEdit", this.componentDetailsForEdit)
      for (let index = 0; index < this.fieldJsonData.card_list.length; index++) {
        for (let i = 0; i < this.errorArray.length; i++) {
          if (index === i) {
            if (this.fieldJsonData.card_list[index].image_url === '') {
              this.errorArray[i].image_url_err = true
              this.isError = true
            }
            if (this.fieldJsonData.card_list[index].image_alt === '') {
              this.errorArray[i].image_alt_err = true
              this.isError = true
            }
          }
        }
      }
      if (this.isError === false) {
        // for (let index = 0; index < this.fieldJsonData.card_list.length; index++) {
        //     delete this.fieldJsonData.card_list[index].image_url_err
        //     delete this.fieldJsonData.card_list[index].image_alt_err
        // }
        this.$emit('objData', this.fieldJsonData)
        this.componentDetailsForEdit = null
        let compIdImage = {
          imageUrl: this.componentImageUrl,
          id: this.componentId
        }
        // this.$emit('imageData', this.componentImageUrl)
        this.$emit('compIdImage', compIdImage)
        this.$root.$emit('closeComponentDialog', true)
        console.log("this.fieldJsonData............", this.fieldJsonData)
      }
    },
    closeDetails () {
    this.$root.$emit('closeComponentDialog', true)
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
/* .list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
} */

::placeholder {
  font-size: 16px;
}
</style>